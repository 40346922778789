import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ContentWithPaddingXl, Container } from "components/misc/Layouts.js";
import { SectionHeading as Heading } from "components/misc/Headings.js";

// Partner logos
import IPBankLogo from "images/Partners/ipbank-logo.png";

const PartnersWrapper = tw.div`flex justify-center items-center mt-12`;
const PartnersGrid = styled.div`
  ${tw`flex flex-wrap justify-center items-center gap-8`}
`;
const PartnerCard = tw.div`flex items-center justify-center p-4`;
const PartnerLogo = styled.img`
  ${tw`max-w-full h-20`}
`;

export default ({
  heading = "Our Partners",
  partners = [
    { logo: IPBankLogo, alt: "Microsoft Partner", link: "/partners" },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <Heading>{heading}</Heading>
        <PartnersWrapper>
          <PartnersGrid>
            {partners.map((partner, index) => (
              <PartnerCard key={index}>
                <a href={partner.link}>
                  <PartnerLogo src={partner.logo} alt={partner.alt} />
                </a>
              </PartnerCard>
            ))}
          </PartnersGrid>
        </PartnersWrapper>
      </ContentWithPaddingXl>
    </Container>
  );
};

import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading } from "components/misc/Headings.js";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/dark.js";
import Footer from "components/footers/SimpleFiveColumn";
import IPBankLogo from "../images/Partners/ipbank-logo.png";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div`xl:mr-12 xl:last:mr-0`;
const HeadingColumn = styled(Column)((props) => [
  tw`w-full xl:w-5/12`,
  props.textOnLeft ? tw`xl:order-first` : tw`xl:order-last xl:ml-12 xl:mr-0`,
]);
const CardColumn = tw(
  Column
)`w-full md:w-1/2 xl:w-1/2 mt-16 xl:mt-0 xl:last:ml-auto`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`mt-4 xl:text-left leading-tight`;
const HeadingTitleTrademark = tw(
  SectionHeading
)`-mt-12 xl:text-left leading-tight`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-base font-medium leading-relaxed text-secondary-500 mt-4`;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

const Card = tw.div`mx-auto`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-48 bg-cover bg-center rounded`,
]);

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardCompany = tw.div`text-primary-500 font-bold text-lg`;
const CardType = tw.div`font-semibold text-sm text-gray-600`;

const CardTitle = tw.h5`text-sm md:text-base lg:text-base font-medium leading-relaxed text-secondary-500 mt-4`;

const CardMeta = styled.div`
  ${tw`flex flex-row flex-wrap justify-center sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;

const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4 mr-4 last:mr-0`}
  svg {
    ${tw`w-5 h-5 mr-1`}
  }
`;
const CardAction = tw(PrimaryButtonBase)`w-full mt-6`;

export default ({
  subheading = "Our Portfolio",
  headingHtmlComponent = (
    <>
      <span tw="text-4xl">Services</span>

      <span tw="text-primary-500 text-2xl">
        <br /> Design Services
      </span>
    </>
  ),
  headingTwo = (
    <>
      <span tw="text-primary-500 text-2xl">
        <br /> Trademark Services
      </span>
    </>
  ),
  descriptionDesign = (
    <>
      <b>Design Search:</b> Comprehensive analysis to identify existing designs
      and assess uniqueness. <br />
      <b>Design Filing:</b> Professional assistance in preparing and filing
      design applications. <br />
      <b>Design Prosecution:</b> Expert representation during the examination
      process. <br />
      <b>Design Infringement Watch:</b> Continuous monitoring to protect your
      registered designs.
    </>
  ),

  descriptionTrademark = (
    <>
      <b>Trademark Search:</b> In-depth research to ensure your trademark is
      unique and protectable. <br />
      <b>Trademark Filing:</b> Seamless filing of trademark applications with
      accuracy and efficiency. <br />
      <b>Trademark Prosecution:</b> Handling objections and office actions for
      successful registration. <br />
      <b>Trademark Watch:</b> Monitoring newly filed trademarks to prevent
      potential conflicts. <br />
      <b>Infringement Actions:</b> Legal support to address unauthorized use of
      your trademark. <br />
      <b>Trademark Oppositions:</b> Representation in opposition proceedings to
      safeguard your trademark rights.
    </>
  ),

  linkText = "View all Projects",
  cardLinkText = "Visit",
  textOnLeft = false,
}) => {
  const cards = [
    {
      imageSrc: IPBankLogo,
      company: "(https://ipbank.in)",
      type: "Ad Campaign",
      title:
        "IP Bank is a private limited company, established under Indian laws, specializing in providing comprehensive intellectual property solutions to individuals, businesses, and organizations. With a strong commitment to excellence, IP Bank ensures the protection and management of your valuable intellectual assets.",
      titleTwo:
        "We are proud to collaborate with IP Bank for intellectual property related services, including:",
      durationText: "90 Days Campaign",
      locationText: "New York",
    },
  ];
  return (
    <AnimationRevealPage>
      <Header />
      <SectionHeading>Our Partners</SectionHeading>

      <Container>
        <Content>
          <ThreeColumn>
            <HeadingColumn textOnLeft={textOnLeft}>
              <HeadingInfoContainer>
                <HeadingTitle>{headingHtmlComponent}</HeadingTitle>
                <HeadingDescription>{descriptionDesign}</HeadingDescription>
                <HeadingTitleTrademark>{headingTwo}</HeadingTitleTrademark>
                <HeadingDescription>{descriptionTrademark}</HeadingDescription>
              </HeadingInfoContainer>
            </HeadingColumn>
            {cards.map((card, index) => (
              <CardColumn key={index}>
                <Card>
                  <CardImage imageSrc={card.imageSrc} />
                  <CardText>
                    <CardTitle>{card.title}</CardTitle>
                    <CardTitle>{card.titleTwo}</CardTitle>

                    <PrimaryLink href="https://www.ipbank.in" target="_blank">
                      {cardLinkText} <ArrowRightIcon />
                    </PrimaryLink>
                  </CardText>
                </Card>
              </CardColumn>
            ))}
          </ThreeColumn>
        </Content>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

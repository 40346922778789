// Homepage.js
import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput";
import ServicesandContact from "components/cards/ServicesAndContact";
import Footer from "components/footers/SimpleFiveColumn";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage";
import VisionAndMission from "components/features/DashedBorderSixFeatures";
export default function Homepage() {
  const servicesRef = useRef(null);
  const location = useLocation();

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get("scrollTo") === "services") {
      scrollToSection(servicesRef);

      // Remove the scrollTo parameter from the URL
      window.history.replaceState({}, document.title, "/");
    }
  }, [location.search]);

  return (
    <AnimationRevealPage>
      <Hero />
      <VisionAndMission />
      <div ref={servicesRef}>
        <ServicesandContact />
      </div>
      <Testimonial />
      <Footer />
    </AnimationRevealPage>
  );
}

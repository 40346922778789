import React, { useEffect } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logo from "../../images/logo.png";

import { ReactComponent as PenPaper } from "../../images/blackPenPaper.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-base lg:mx-6 lg:my-0 lg:text-gray-900 text-gray-100
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100 lg:text-gray-900
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-4xl! ml-0! text-gray-900`};
  font-family: "Italianno", cursive;
`;
export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-gray-600`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = "lg",
  scrollToSection,
  homeRef,
  servicesRef,
  teamMembersRef,
  contactUsRef,
}) => {
  const defaultLinks = [
    <NavLinks key={1} tw="flex items-center">
      <NavLink as={Link} to="/">
        Home
      </NavLink>

      <NavLink as={Link} to="/about-us">
        About Us
      </NavLink>
      <NavLink as={Link} to="/?scrollTo=services">
        Services
      </NavLink>
      <NavLink as={Link} to="/team">
        Team
      </NavLink>
      <NavLink as={Link} to="/advisors">
        Advisors
      </NavLink>
      <NavLink as={Link} to="/contact-us">
        Contact Us
      </NavLink>
      {/* WhatsApp Link */}
      <NavLink
        href="https://wa.me/918864859608" // Replace with your WhatsApp number
        target="_blank"
        rel="noopener noreferrer"
        tw="hidden lg:flex flex items-center justify-center animate-fastPulse"
      >
        <FaWhatsapp tw="text-2xl mr-2" />
      </NavLink>
      {/* <NavLink
        as={Link}
        to="/model-question-paper"
        tw="flex items-center animate-fastPulse"
      >
        <PenPaper tw="w-5 h-5 mr-2" /> PAE - 2025
      </NavLink> */}
    </NavLinks>,
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} alt="Company Logo" tw="h-20 w-auto rounded-full" />
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        {logoLink}
        <MobileNavLinks
          initial={{ x: "150%", display: "none" }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          {links}
        </MobileNavLinks>
        <div tw="lg:hidden flex items-center space-x-4">
          {/* Menu Toggle */}
          <NavToggle
            onClick={toggleNavbar}
            className={showNavLinks ? "open" : "closed"}
          >
            {showNavLinks ? (
              <CloseIcon tw="w-6 h-6" />
            ) : (
              <MenuIcon tw="w-6 h-6" />
            )}
          </NavToggle>

          {/* WhatsApp Icon */}
          <a
            href="https://wa.me/918864859608"
            target="_blank"
            rel="noopener noreferrer"
            tw="bg-transparent flex items-center justify-center animate-fastPulse"
          >
            <FaWhatsapp tw="text-xl" />
          </a>
        </div>
      </MobileNavLinksContainer>
    </Header>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
